<template>
  <div class="row">
    <!-- <div v-show="impr.ibmStepCd !== 'IS00000001' && impr.suppleCheckFlag !== 'Y'" class="col-12"> -->
    <div v-show="impr.ibmStepCd !== 'IS00000001'" class="col-12">
      <c-step-mobile
        stepperGrpCd="IBM_STEP_CD"
        :currentStepCd="impr.ibmStepCd"
        :disabled="true"
        v-model="impr.ibmStepCd">
      </c-step-mobile>
    </div>
    <!-- <div v-show="suppleFlag" class="col-12">
      <c-step-mobile
        stepperGrpCd="IBM_SUPPLE_STEP_CD"
        :currentStepCd="impr.ibmSuppleStepCd"
        :disabled="true"
        v-model="impr.ibmSuppleStepCd">
      </c-step-mobile>
    </div> -->
    <div v-if="overFlag && this.impr.approvalStatusCd !== 'ASC9999999' && impr.delayFlag !== 'Y'" class="col-12">
      <q-banner dense inline-actions class="text-white bg-red">
        <template v-slot:avatar>
          <q-icon name="report_problem" color="grey-3" />
        </template>
        <b class="text-h5">지연</b>
      </q-banner>
    </div>
    <!-- <div v-show="impr.suppleCheckFlag === 'Y'" 
      :class="impr.saveSuppleFlag === 'Y' ? 'col-xs-12 col-sm-12 col-md-12 col-lg-6' : 'col-12'">
      <div class="row">
        <div class="col-12">
          <q-form ref="editSuppleForm"> 
            <c-card title="보완 요청 상세" class="cardClassDetailForm">
              <template slot="card-description">
                <font v-if="!impr.saveSuppleFlag" class="text-negative" style="font-size:0.8em;font-weight:300;">
                  (적합/보완 처리가 되지 않았습니다.)
                </font>
              </template>
              <template slot="card-detail">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4">
                  <c-select
                    :editable="suppleEditable"
                    :comboItems="suppleFlagItems"
                    itemText="codeName"
                    itemValue="code"
                    label="적합/보완 여부"
                    name="suppleFlag"
                    v-model="impr.suppleFlag"
                    @datachange="supplechange">
                  </c-select>
                </div>
                <div v-if="impr.saveSuppleFlag === 'Y'" class="col-xs-6 col-sm-6 col-md-6 col-lg-4">
                  <c-datepicker
                    :editable="suppleRequestEditable"
                    :required="true"
                    label="보완완료 요청일"
                    name="suppleScheduleDate"
                    v-model="impr.suppleScheduleDate"
                  />
                </div>
                <div v-if="impr.saveSuppleFlag === 'Y'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="suppleRequestEditable"
                    :required="true"
                    label="보완요청내용"
                    name="suppleRequest"
                    v-model="impr.suppleRequest">
                  </c-textarea>
                </div>
                <div v-show="impr.saveSuppleFlag === 'Y'" class="col-12">
                  <c-upload 
                    ref="suppleAttach"
                    :attachInfo="attachSupple"
                    :editable="suppleAttachEditable"
                    label="보완 관련 첨부파일">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div> -->
    <!-- <div v-show="impr.suppleCheckFlag === 'Y' && impr.saveSuppleFlag === 'Y'" class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row">
        <div class="col-12">
          <q-form ref="editSuppleActionReviewForm"> 
            <c-card title="보완 조치&검토 상세" class="cardClassDetailForm">
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                  <c-datepicker
                    :editable="suppleActionEditable"
                    :required="true"
                    label="보완완료일"
                    name="suppleCompleteDate"
                    v-model="impr.suppleCompleteDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="suppleActionEditable"
                    :required="true"
                    :rows="4"
                    label="보완처리내용"
                    name="suppleActionContents"
                    v-model="impr.suppleActionContents">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="suppleReviewEditable"
                    :rows="4"
                    label="보완검토내용"
                    name="suppleReviewContents"
                    v-model="impr.suppleReviewContents">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div> -->
    <div class="col-12" v-show="isTargetReset&&!(impr.completeDateOver > 0 && impr.ibmStepCd !== 'IS00000001')">
      <q-list bordered padding dense class="impr-delay-list">
        <q-item tag="label" v-ripple :active="impr.delayFlag==='Y'" active-class="impr-delay-active">
          <q-item-section side top>
            <q-checkbox 
              true-value="Y"
              false-value="N"
              v-model="impr.delayFlag" />
          </q-item-section>

          <q-item-section>
            <q-item-label>목표일 재설정</q-item-label>
            <q-item-label caption>
              ※ 재설정 시에 지연 프로세스가 동작하며, 재설정한 목표일을 넘길 시 지연 프로세스가 동작하지 않습니다.
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="col-12" v-show="overFlag">
      <q-form ref="overForm">
        <c-card title="지연상세" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <c-datepicker
                :editable="editable"
                :disabled="disabled||impr.approvalStatusCd==='ASC9999999'"
                :required="true"
                :start="impr.actionCompleteRequestDate"
                label="목표일"
                name="targetDate"
                v-model="impr.targetDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-textarea
                :editable="editable"
                :disabled="disabled||impr.approvalStatusCd==='ASC9999999'"
                :required="true"
                label="지연사유"
                name="delayReason"
                v-model="impr.delayReason">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm1">
        <c-card title="요청&접수 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- <c-btn
                v-show="requestBtnEditable"
                :isSubmit="isRequest"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="조치부서에 요청"
                icon="send"
                @beforeAction="requestImpr"
                @btnCallback="requestCallback" />
              <c-btn
                v-show="receiptBtnEditable"
                :isSubmit="isReceipt"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="접수"
                icon="directions_run"
                @beforeAction="receiptImpr"
                @btnCallback="receiptCallback" />
              <c-btn
                v-show="saveBtnEditable1"
                :isSubmit="isSaveRequestReceipt"
                :url="saveUrl"
                :param="impr"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveRequestReceiptImpr"
                @btnCallback="saveRequestReceiptCallback" />
              <c-btn
                v-show="requestCancelBtnEditable"
                :isSubmit="isReject"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="요청취소"
                icon="delete_forever"
                @beforeAction="rejectImpr"
                @btnCallback="rejectCallback" />
              <c-btn 
                v-show="requestAreaEditable&&popupParam.sopImprovementId" 
                :isSubmit="isDelete"
                :url="deleteUrl"
                :param="impr"
                mappingType="DELETE"
                label="삭제" 
                icon="remove"
                @beforeAction="removeImpr"
                @btnCallback="removeCallback" /> -->
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-select
                :disabled="true"
                :editable="editable"
                codeGroupCd="IBM_TASK_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="ibmTaskTypeCd"
                label="업무"
                v-model="impr.ibmTaskTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :disabled="true"
                :editable="editable"
                name="sopImprovementId"
                label="개선번호"
                v-model="impr.sopImprovementId"
              ></c-text>
            </div>
            <div class="col-xs-6 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :editable="editable"
                :disabled="true"
                label="요청부서/이름/일자"
                name="request"
                :value="request">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="requestAreaEditable"
                :required="true"
                label="제목"
                name="ibmTitle"
                v-model="impr.ibmTitle">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="requestAreaEditable"
                :required="true"
                label="요청내용"
                name="improveRequestContents"
                v-model="impr.improveRequestContents">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="row">
                <div class="col-7">
                  <c-plant
                    :editable="requestAreaEditable"
                    :required="true"
                    type="edit" 
                    name="plantCd" 
                    v-model="impr.plantCd" />
                </div>
                <div class="col-5">
                  <c-checkbox
                    :editable="requestAreaEditable"
                    :isFlag="true"
                    trueLabel="업체"
                    falseLabel="부서"
                    trueValue="V"
                    falseValue="D"
                    label=""
                    name="actionFlag"
                    v-model="impr.actionFlag"
                    @datachange="changeAction"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-dept 
                v-show="impr.actionFlag === 'D'"
                :editable="requestAreaEditable||actionReceiptAreaEditable"
                :required="impr.actionFlag === 'D'"
                :searchFlag="actionReceiptAreaEditable ? false : true"
                :closeFlag="actionReceiptAreaEditable ? false : true"
                type="edit" 
                label="조치부서" 
                name="actionDeptCd" 
                v-model="impr.actionDeptCd">
                <template slot="custom-btn-icon">
                  <q-icon v-if="actionReceiptAreaEditable" name="autorenew" @click="changeActionDeptImpr" class="cursor-pointer">
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
                      <strong>부서변경</strong>
                    </q-tooltip>
                  </q-icon>
                </template>
              </c-dept>
              <c-vendor 
                v-show="impr.actionFlag === 'V'"
                :editable="requestAreaEditable||actionReceiptAreaEditable"
                :required="impr.actionFlag === 'V'"
                :selfFlag="false"
                type="edit" 
                label="조치업체" 
                name="actionVendorCd" 
                v-model="impr.actionVendorCd" />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <!-- type="dept_user" -->
              <c-multi-field 
                v-show="impr.actionFlag === 'D'"
                :editable="actionReceiptAreaEditable"
                :required="true"
                :userInfo.sync="userInfo"
                isArray
                label="조치자" 
                name="actionUserIds" 
                v-model="impr.actionUserIds" />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                :editable="requestAreaEditable"
                :required="true"
                label="조치완료 요청일"
                name="actionCompleteRequestDate"
                v-model="impr.actionCompleteRequestDate"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <!-- type="dept_user" -->
              <c-field
                :editable="requestAreaEditable||actionReceiptAreaEditable"
                :data="impr"
                deptValue="actionCompleteCheckDeptCd"
                label="조치완료 확인자"
                name="actionCompleteCheckUserId"
                v-model="impr.actionCompleteCheckUserId" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="actionReceiptAreaEditable"
                label="요청취소 사유"
                name="requestRejectReason"
                v-model="impr.requestRejectReason">
              </c-textarea>
            </div>
            <div class="col-12">
              <c-upload 
                :attachInfo="attachBeforeInfo"
                :editable="requestAreaEditable||actionReceiptAreaEditable||actionAreaEditable"
                label="개선 전 사진">
              </c-upload>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <q-form ref="editForm2">
            <c-card title="조치상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <!-- <c-btn
                    v-show="actionCompleteBtnEditable"
                    :isSubmit="isActionComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="조치완료"
                    icon="directions_run"
                    @beforeAction="actionCompleteImpr"
                    @btnCallback="actionCompleteCallback" />
                  <c-btn
                    v-show="saveBtnEditable2"
                    :isSubmit="isSaveAction"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장"
                    icon="save"
                    @beforeAction="saveActionImpr"
                    @btnCallback="saveActionCallback" /> -->
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치완료일"
                    name="actionCompleteDate"
                    v-model="impr.actionCompleteDate"
                  />
                </div>
                <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="검토완료 요청일"
                    name="reviewCompleteRequestDate"
                    v-model="impr.reviewCompleteRequestDate"
                  />
                </div> -->
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <c-textarea
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치내용"
                    name="actionContents"
                    v-model="impr.actionContents">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-upload 
                    :attachInfo="attachAfterInfo"
                    :editable="actionAreaEditable"
                    label="개선 후 사진">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <q-form ref="editForm3">
            <c-card title="검토상세" class="cardClassDetailForm">
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <c-textarea
                    :editable="requestCheckAreaEditable"
                    :required="true"
                    label="검토내용"
                    name="reviewContents"
                    v-model="impr.reviewContents">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div> -->
      </div>
    </div>
    <q-dialog
      ref="deptDialog"
      position="bottom"
      persistent
      v-model="isOpen">
      <q-card class="dept-card">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-7 text-white">
          <div class="text-h6">조치부서 변경</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectDept"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline square color="primary" text-color="white">
                {{deptName}}
              </q-chip>
              <q-input ref="filter" dense v-model="filter" label="Filter">
                <template v-slot:append>
                  <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
                </template>
              </q-input>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>
        <q-card-section class="dept-content p-2">
          <q-tree
            ref="imprActionDept"
            :nodes="deptTree"
            node-key="deptCd"
            label-key="deptName"
            children-key="children"
            no-nodes-label="부서 정보가 없습니다."
            no-results-label="필터링 된 데이터가 없습니다."
            :filter="filter"
            :default-expand-all="true"
            selected-color="primary"
            :selected.sync="selected"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <component
            :is='relationTableInfo.component' 
            :key='relationTableInfo.key' 
            :taskParam="relationTableInfo.taskParam"
            :disabled="!Boolean(popupParam.sopImprovementId)"
          />
          <!-- 보완요청 상세S -->
          <!-- <c-btn 
            :flat="true"
            v-show="suppleRequestEditable" 
            :isSubmit="isSaveSuppleRequest"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="보완요청" 
            icon="send" 
            @beforeAction="saveSuppleRequest"
            @btnCallback="saveSuppleRequestCallback" />
          <c-btn 
            :flat="true"
            v-show="suppleRequestEditable" 
            :isSubmit="isSaveSupple"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveSupple"
            @btnCallback="saveSuppleCallback" /> -->
          <!-- 보완요청 상세E -->
          <!-- 보완 조치&검토 상세 S -->
          <!-- <c-btn 
            v-show="suppleReviewEditable" 
            :isSubmit="isSaveSuppleComplete"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="보완완료" 
            icon="directions_run" 
            @beforeAction="saveSuppleComplete"
            @btnCallback="saveSuppleCompleteCallback" />
          <c-btn 
            v-show="suppleActionEditable" 
            :isSubmit="isSaveSuppleReview"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="검토요청" 
            icon="send" 
            @beforeAction="saveSuppleReview"
            @btnCallback="saveSuppleReviewCallback" />
          <c-btn 
            v-show="suppleActionReviewBtnEditable" 
            :isSubmit="isSaveSuppleActionReview"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveSuppleActionReview"
            @btnCallback="saveSuppleActionReviewCallback" />
          <c-btn 
            v-show="suppleReviewEditable" 
            :isSubmit="isReturnSupple"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="반려" 
            icon="front_hand"
            @beforeAction="returnSupple"
            @btnCallback="returnSuppleCallback" /> -->
          <!-- 보완 조치&검토 상세 E -->
          <!-- 검토S -->
          <!-- <c-btn
            :flat="true"
            v-show="rejectBtnEditable"
            :isSubmit="isReturn"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="반려"
            icon="front_hand"
            @beforeAction="returnImpr"
            @btnCallback="returnCallback" />
          <c-btn
            :flat="true"
            v-show="completeBtnEditable"
            :isSubmit="isComplete"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="개선완료"
            icon="check_circle_outline"
            @beforeAction="completeImpr"
            @btnCallback="completeCallback" />
          <c-btn
            :flat="true"
            v-show="saveBtnEditable3"
            :isSubmit="isSaveCheck"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveCheckImpr"
            @btnCallback="saveCheckCallback" /> -->
          <!-- 검토E -->
          <!-- 지연S -->
          <c-appr-btn 
            v-show="overFlag"
            :flat="true"
            ref="appr-btn"
            name="work-permit-appr-btn"
            :editable="editable&&impr.approvalStatusCd !== 'ASC9999999'"
            :approvalInfo="approvalInfo"
            historyBtnLabel="지연 결재이력"
            reApprBtnLabel="지연 재상신"
            apprBtnLabel="지연 결재요청"
            @beforeApprAction="approvalOverImpr"
            @callbackApprAction="approvalOverImrpCallback"
            @requestAfterAction="getDetail"
          />
          <c-btn 
            :flat="true"
            v-show="editable&&!disabled&&impr.approvalStatusCd !== 'ASC9999999'&&overFlag" 
            :isSubmit="isOver"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveOverImpr"
            @btnCallback="saveOverCallback" />
          <!-- 지연E -->
          <!-- 요청&접수 상세 S -->
          <c-btn 
            :flat="true"
            v-show="requestAreaEditable&&popupParam.sopImprovementId" 
            :isSubmit="isDelete"
            :url="deleteUrl"
            :param="impr"
            mappingType="DELETE"
            label="삭제" 
            icon="remove"
            @beforeAction="removeImpr"
            @btnCallback="removeCallback" />
          <c-btn
            :flat="true"
            v-show="requestBtnEditable"
            :isSubmit="isRequest"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="조치대상에 요청"
            icon="send"
            @beforeAction="requestImpr"
            @btnCallback="requestCallback" />
          <c-btn
            :flat="true"
            v-show="requestCancelBtnEditable"
            :isSubmit="isReject"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="요청취소"
            icon="delete_forever"
            @beforeAction="rejectImpr"
            @btnCallback="rejectCallback" />
          <c-btn
            :flat="true"
            v-show="receiptBtnEditable"
            :isSubmit="isReceipt"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="접수"
            icon="directions_run"
            @beforeAction="receiptImpr"
            @btnCallback="receiptCallback" />
          <c-btn
            :flat="true"
            v-show="saveBtnEditable1"
            :isSubmit="isSaveRequestReceipt"
            :url="saveUrl"
            :param="impr"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveRequestReceiptImpr"
            @btnCallback="saveRequestReceiptCallback" />
          <!-- 요청&접수 상세 E -->
          <!-- 조치상세 S -->
          <c-btn
            :flat="true"
            v-show="actionCompleteBtnEditable"
            :isSubmit="isComplete"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="개선완료"
            icon="check_circle_outline"
            @beforeAction="completeImpr"
            @btnCallback="completeCallback" />
          <!-- <c-btn
            :flat="true"
            v-show="actionCompleteBtnEditable"
            :isSubmit="isActionComplete"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="조치완료"
            icon="directions_run"
            @beforeAction="actionCompleteImpr"
            @btnCallback="actionCompleteCallback" /> -->
          <c-btn
            :flat="true"
            v-show="saveBtnEditable2"
            :isSubmit="isSaveAction"
            :url="saveUrl"
            :param="impr"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveActionImpr"
            @btnCallback="saveActionCallback" />
          <!-- 조치상세 E -->
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import arrayToTree from 'array-to-tree';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          isSearch: false,
          sopImprovementId: '',
          ibmTaskTypeCd: '',
          ibmTaskUnderTypeCd: '',  // 업무하위분류코드
          data: null,
          requestContents: '',
          relationTableKey: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    }
  },
  data() {
    return {
      impr: {
        sopImprovementId: '',  // 개선 일련번호
        plantCd: '',  // 사업장 코드
        ibmTaskTypeCd: '',  // 업무분류코드
        ibmTaskUnderTypeCd: '',  // 업무하위분류코드
        ibmTitle: '',  // 제목
        request: '',
        improveRequestDeptCd: '',  // 개선요청부서
        improveRequestDeptName: '', // 개선요청부서명
        improveRequestUserId: '',  // 개선요청자
        improveRequestUserName: '',  // 개선요청자명
        improveRequestContents: '',  // 개선요청내용
        ibmStepCd: '',  // 개선진행단계
        relationTableKey: '',  // 관련테이블 키
        relationVendor: '',  // 관련업체_기술
        actionScheduleDate: '',  // 조치예정일
        actionCompleteRequestDate: '',  // 조치완료 요청일
        reviewCompleteRequestDate: '',  // 검토완료 요청일
        actionCompleteDate: '',  // 조치완료일
        actionFlag: 'D',  // 조치부서_조치부서는 변경가능
        actionVendorCd: '',  // 조치부서_조치부서는 변경가능
        actionDeptCd: '',  // 조치부서_조치부서는 변경가능
        actionUserIds: [],  // 조치자s
        actionContents: '',  // 조치내용_조치부서에서
        reviewContents: '',  // 요청부서 조치결과 검토_요청부서에서
        actionCompleteCheckUserId: '',  // 조치완료 확인자_부서장 또는 공장장
        actionCompleteCheckFlag: '',  // 조치완료 확인자 확인 여부
        requestRejectReason: '', // 요청취소사유

        targetDate: '',  // 목표일 - 지연
        delayFlag: 'N', // 지연 강제 프로세스
        delayReason: '',  // 지연사유
        completeDateOver: 0,
        
        // saveSuppleFlag: '', // 적합/보완 여부 저장된 정보
        // suppleFlag: '',  // 적합/보완 여부
        // ibmSuppleStepCd: '',  // 개선보완진행단계
        // suppleRequest: '',  // 보완할 사항
        // suppleScheduleDate: '',  // 보완완료 요청일
        // suppleActionContents: '',  // 보완처리 내용
        // suppleCompleteDate: '',  // 보완완료일
        // suppleReviewContents: '',  // 보완 검토 내용
        // suppleCheckFlag: 'N',

        sopConstructionId: '',

        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',  // 등록자 ID
        regDt: '',
        chgUserId: '',  // 수정자 ID
        actionUsers: [],
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_AFTER',
        taskKey: '',
      },
      // attachSupple: {
      //   isSubmit: '',
      //   taskClassCd: 'IBM_SUPPLE',
      //   taskKey: '',
      // },
      deptCd: '',
      deptName: '부서를 선택하세요',
      isOpen: false,
      filter: '',
      selected: null,
      editable: true,
      mappingType: 'PUT',
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSaveRequestReceipt: false, // 요청&접수 저장 flag
      isRequest: false, // 요청 flag
      isReceipt: false, // 접수 flag
      isReject: false, // 요청취소 flag
      isDelete: false, // 삭제 flag
      isActionComplete: false, // 조치완료 flag
      isSaveAction: false, // 조치 저장 flag
      isSaveCheck: false, // 검토 저장 flag
      isComplete: false, // 개선완료 flag
      isReturn: false, // 조치반려 flag
      isOver: false, // 지연 저장 flag
      isApproval: false, // 지연 결재 flag
      // isSaveSuppleRequest: false, // 보완 요청 flag
      // isSaveSupple: false, // 보완 정보 저장 flag
      // isSaveSuppleActionReview: false, // 보완 정보 저장 flag
      // isSaveSuppleComplete: false, // 보완완료 flag
      // isSaveSuppleReview: false, // 보완 검토요청 flag
      // isReturnSupple: false, // 보완 반려 flag
    };
  },
  computed: {
    request() {
      return this.impr.improveRequestDeptName + ' / ' + this.impr.improveRequestUserName + ' / '  + this.impr.regDt
    },
    disabled() {
      return this.popupParam.isSearch || this.impr.ibmStepCd === 'IS00000020'
        // 결재중인 경우 비활성화
        || this.impr.approvalStatusCd === 'ASC0000001';
    },
    imprEditable() {
      return this.editable && !this.disabled && (this.impr.approvalStatusCd === 'ASC9999999' ? true : !this.overFlag)
    },
    requestAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    actionReceiptAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    actionAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    requestCheckAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    // suppleAttachEditable() {
    //   return this.editable 
    //     && this.impr.ibmStepCd === 'IS00000020' // 개선완료
    //     && this.impr.suppleCheckFlag === 'Y' // 보완 작성 여부
    //     && this.impr.ibmSuppleStepCd !== 'ISS0000015' // 보완완료 상태인 경우
    //     && this.impr.saveSuppleFlag === 'Y'
    // },
    // suppleEditable() {
    //   return this.editable 
    //     && this.impr.ibmStepCd === 'IS00000020' // 개선완료
    //     && this.impr.suppleCheckFlag === 'Y' // 보완 작성 여부
    //     && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    //     && this.impr.ibmSuppleStepCd !== 'ISS0000015' // 보완 진행단계가 없거나 요청상태인 경우
    // },
    // suppleRequestEditable() {
    //   return this.editable 
    //     && this.impr.ibmStepCd === 'IS00000020' // 개선완료
    //     && this.impr.suppleCheckFlag === 'Y' // 보완 작성 여부
    //     && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    //     && this.impr.ibmSuppleStepCd === 'ISS0000001' // 보완 요청상태인 경우
    // },
    // suppleActionEditable() {
    //   return this.editable 
    //     && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    //     && this.impr.ibmSuppleStepCd === 'ISS0000005' // 보완 조치상태인 경우
    // },
    // suppleReviewEditable() {
    //   return this.editable 
    //     && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    //     && this.impr.ibmSuppleStepCd === 'ISS0000010' // 보완 검토상태인 경우
    // },
    // suppleActionReviewBtnEditable() {
    //   return this.editable 
    //     && (this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd || this.impr.actionDeptCd === this.$store.getters.user.deptCd) // 로그인자의 부서가 요청부서이거나 조치부서인 경우
    //     && (this.impr.ibmSuppleStepCd === 'ISS0000005' || this.impr.ibmSuppleStepCd === 'ISS0000010') // 보완 조치 또는 검토 상태인 경우
    // },
    requestBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.popupParam.sopImprovementId // key가 있는 경우, 저장이 한번 된 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    receiptBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    requestCancelBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    actionCompleteBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    rejectBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    completeBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    tempSaveBtnEditable() {
      return this.imprEditable
        && this.popupParam.data && !this.popupParam.sopImprovementId
    },
    saveBtnEditable1() {
      return this.imprEditable
        && ((this.impr.ibmStepCd === 'IS00000001' // 요청중 and 로그인자의 부서가 요청부서
          && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd)
        )
        // || (this.impr.ibmStepCd === 'IS00000005'
        //  && this.impr.actionDeptCd === this.$store.getters.user.deptCd)) // 조치부서 접수중 and 조치부서인 경우
    },
    saveBtnEditable2() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    saveBtnEditable3() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    // suppleFlag() {
    //   return this.impr.saveSuppleFlag === 'Y' 
    //     && this.impr.ibmSuppleStepCd
    // },
    userInfo() {
      return {
        userItems: this.impr.actionUsers,
        userText: 'actionUserName',
        userValue: 'actionUserId',
        deptText: 'actionDeptName',
        deptValue: 'actionDeptCd',
      }
    },
    isTargetReset() {
      /**
       * 목표일 재설정 여부
       */
      return this.editable
        && this.impr.approvalStatusCd !== 'ASC9999999'
        && !this.disabled
         // 조치부서 접수중, 조치중 상태
        && (this.impr.ibmStepCd === 'IS00000005' || this.impr.ibmStepCd === 'IS00000010')
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    overFlag() {
      /**
       * 지연된 데이터 체크
       * 접수중 단계부터 체크하며 지연된 데이터의 경우 지연 프로세스 동작
       * 지연 정보를 제외한 나머지 정보는 비활성화 처리
       */
      return (this.impr.completeDateOver > 0 && this.impr.ibmStepCd !== 'IS00000001')
        || this.impr.delayFlag === 'Y'
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.impr.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.impr.approvalStatusCd, 
        apprEditable: this.overFlag, // 결재버튼 활성화 기준
        param: this.impr, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000007', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          sopImprovementId: this.impr.sopImprovementId,
          isApprContent: true
        },
        approvalRequestName: this.impr.ibmTitle, // 결재요청명 (문서 title)
        approvalConnId: this.impr.sopImprovementId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    depts() {
      let list = [];
      if (this.$store.getters.user.plantCds !== null && this.$store.getters.user.plantCds !== '') {
        let plantCds = this.$store.getters.user.plantCds.split(',');
        this.$_.forEach(this.$store.state.systemInfo.depts, _item => {
          let index = plantCds.findIndex(a => a === _item.plantCd);
          if (index > -1) {
            list.push(_item);
          }
        })
      } else {
        list = this.$_.filter(this.$store.state.systemInfo.depts, { plantCd: this.$store.getters.user.plantCd });
      }
      if (this.impr.plantCd) {
        list = this.$_.filter(list, { plantCd: this.impr.plantCd });
      }
      return list;
    },
    deptTree() {
      let val = this.convertTree(this.depts);
      return val ? val : [];
    },
    // suppleFlagItems() {
    //   if (this.impr.saveSuppleFlag) {
    //     return [
    //       { code: 'Y', codeName: '보완' },
    //       { code: 'N', codeName: '적합' },
    //     ];
    //   } else {
    //     return [
    //       { code: '', codeName: '선택' },
    //       { code: 'Y', codeName: '보완' },
    //       { code: 'N', codeName: '적합' },
    //     ];
    //   }
    // },
    relationTableInfo() {
      return {
        component: () => import(`${'@/pages/sop/ibm/imprRelationTag.vue'}`),
        key: uid(),
        taskParam: this.impr
      }
    }
  },
  watch: {
    selected() {
      let data = this.$refs['imprActionDept'].getNodeByKey(this.selected);

      this.deptCd = this.$_.clone(this.selected);
      this.deptName = data.deptName;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.get.url;
      this.saveUrl = transactionConfig.sop.ibm.improve.update.url
      this.deleteUrl = transactionConfig.sop.ibm.improve.delete.url
      this.approvalUrl = transactionConfig.sop.ibm.improve.approval.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopImprovementId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopImprovementId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.impr, _result.data);

          // this.impr.saveSuppleFlag = this.$_.clone(this.impr.suppleFlag)

          this.attachBeforeInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)
          this.attachAfterInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)
          // this.attachSupple.taskKey = this.$_.clone(this.impr.sopImprovementId)

          if (this.impr.actionUsers && this.impr.actionUsers.length > 0) {
            this.$set(this.impr, 'actionUserIds', this.$_.map(this.impr.actionUsers, 'actionUserId'))
          } else {
            this.$set(this.impr, 'actionUserIds', [])
          }

          this.returnData.col1 = this.impr;
        },);
      } else {
        /**
         * 데이터가 들어오지 않은 경우 두 가지의 case로 처리
         *
         * case 1. popupParam.data가 있는 경우
         *  - 해당 데이터에 새로 들어온 데이터가 있음으로 해당 데이터를 impr에 extend 처리
         * case 2. popupParam.data가 없는 경우 경우
         *  - 해당 팝업으로 개선요청을 등록하는 경우로 업무 및 요청정보를 setting한다.
         */
        if (this.popupParam.data) {
          // case 1.
          this.$_.extend(this.impr, this.popupParam.data)
        } else {
          // case 2.
          this.impr.ibmClassCd = 'IC00000001';
          this.impr.ibmStepCd = 'IS00000001'
          this.impr.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
          this.impr.ibmTaskUnderTypeCd = this.popupParam.ibmTaskUnderTypeCd;
          this.impr.improveRequestDeptCd = this.$store.getters.user.deptCd;
          this.impr.improveRequestDeptName = this.$store.getters.user.deptName;
          this.impr.improveRequestUserId = this.$store.getters.user.userId;
          this.impr.improveRequestUserName = this.$store.getters.user.userName;
          this.impr.regDt = this.$comm.getToday();
          this.impr.relationTableKey = this.popupParam.relationTableKey;
          if (this.popupParam.requestContents !== undefined) {
            this.impr.ibmTitle = this.popupParam.requestContents + '_' + this.$comm.getToday();
          }
          
        }
      }
    },
    resetFilter () {
      this.filter = ''
      this.$refs.filter.focus()
    },
    /**
     * 조치부서변경
     */
    selectDept() {
      if (this.deptCd === this.impr.actionDeptCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '현재와 동일한 조치부서입니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      if (this.deptName !== '부서를 선택하세요') {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '[' + this.deptName + '] 부서로 변경하시겠습니까?\n\r(* 조치자는 초기화 됩니다.)',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$refs['deptDialog'].hide();
            this.$set(this.impr, 'actionUserIds', [])
            this.$set(this.impr, 'actionDeptCd', this.deptCd)

            this.$http.url = this.saveUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.impr;
            this.$http.request((_result) => {
              this.popupParam.sopImprovementId = _result.data
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    changeActionDeptImpr() {
      this.isOpen = true;
    },
    changeAction(value) {
      if (value === 'D') {
        // 부서
        this.impr.actionVendorCd = null;
      } else if (value === 'V') {
        // 업체
        this.impr.actionDeptCd = null;
      }
    },
    // supplechange(data) {
    //   if (!data.value) return;
    //   let message = '보완이 필요한 개선요청건으로 지정하시겠습니까?'
    //   if (data.value === 'N') {
    //     if (this.impr.ibmSuppleStepCd) {
    //       message = '보완을 위해 작성한 내용이 삭제됩니다.\n\r'
    //     }
    //     message += '적합으로 지정하시겠습니까?'
    //   }
    //   window.getApp.$emit('CONFIRM', {
    //     title: '확인',
    //     message: message,
    //     // TODO : 필요시 추가하세요.
    //     type: 'info', // success / info / warning / error
    //     // 확인 callback 함수
    //     confirmCallback: () => {
    //       this.impr.chgUserId = this.$store.getters.user.userId;
    //       if (data.value === 'Y') {
    //         this.$set(this.impr, 'ibmSuppleStepCd', 'ISS0000001');
    //       } else {
    //         this.$set(this.impr, 'ibmSuppleStepCd', '');
    //         this.$set(this.impr, 'suppleRequest', '');
    //         this.$set(this.impr, 'suppleScheduleDate', '');
    //         this.$set(this.impr, 'suppleActionContents', '');
    //         this.$set(this.impr, 'suppleCompleteDate', '');
    //         this.$set(this.impr, 'suppleReviewContents', '');
    //         this.$refs['suppleAttach'].removeAllFilesMethod();
    //       }

    //       this.$http.url = this.saveUrl;
    //       this.$http.type = 'PUT';
    //       this.$http.param = this.impr;
    //       this.$http.request(() => {
    //         window.getApp.$emit('APP_REQUEST_SUCCESS');
    //         this.getDetail();
    //       },);
    //     },
    //     // 취소 callback 함수
    //     cancelCallback: () => {
    //       this.$set(this.impr, 'suppleFlag', this.impr.saveSuppleFlag)
    //     },
    //   });
    // },
    /**
     * 요청&접수 상세 저장
     */
    saveRequestReceiptImpr() {
      if (this.popupParam.sopImprovementId) {
        this.mappingType = 'PUT';
      } else {
        this.mappingType = 'POST';
      }
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId

              this.isSaveRequestReceipt = !this.isSaveRequestReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveRequestReceiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.taskKey = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 요청
     */
    requestImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치대상에 개선요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000005';

              this.isRequest = !this.isRequest
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    requestCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.taskKey = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 접수
     */
    receiptImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '요청건을 접수하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000010';

              this.isReceipt = !this.isReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    receiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.taskKey = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 요청취소
     */
    rejectImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          if (this.impr.requestRejectReason) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '요청취소하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                this.impr.ibmStepCd = 'IS00000001';

                this.isReject = !this.isReject
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '요청취소사유를 입력하세요.', // 요청취소사유를 입력하세요.
              type: 'warning', // success / info / warning / error
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    rejectCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.taskKey = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 삭제
     */
    removeImpr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.deleteUrl = this.$format(transactionConfig.sop.ibm.improve.delete.url, this.popupParam.sopImprovementId);

          this.isDelete = !this.isDelete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup', this.returnData, 'delete');
    },
    closePopUps() {
      this.$emit('closePopup', this.returnData)
    },
    /**
     * 조치완료
     */
    actionCompleteImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        this.$refs['editForm2'].validate().then(__result => {
          if (_result && __result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '조치완료하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                this.impr.ibmStepCd = 'IS00000015';
                this.impr.stepFlag = 1;
                
                this.isActionComplete = !this.isActionComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      });
    },
    actionCompleteCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveActionImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        this.$refs['editForm2'].validate().then(__result => {
          if (_result && __result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                
                this.isSaveAction = !this.isSaveAction
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      });
    },
    saveActionCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveCheckImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;

              this.isSaveCheck = !this.isSaveCheck
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCheckCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 개선완료
     */
    completeImpr() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '개선 완료처리하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000020';

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치반려
     */
    returnImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치반려하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000010';

              this.isReturn = !this.isReturn
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    returnCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    saveOverImpr() {
      this.$refs['overForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isOver = !this.isOver
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveOverCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    approvalOverImpr() {
      this.$refs['overForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isApproval = !this.isApproval
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approvalOverImrpCallback() {
      this.$refs['appr-btn'].apprRequestPop();
    },
    /**
     * 보완요청
     */
    // saveSuppleRequest() {
    //   this.$refs['editSuppleForm'].validate().then(_result => {
    //     if (_result) {
    //       window.getApp.$emit('CONFIRM', {
    //         title: '확인',
    //         message: '조치부서에 보완조치요청 하시겠습니까?',
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.impr.chgUserId = this.$store.getters.user.userId;
    //           this.impr.ibmSuppleStepCd = 'ISS0000005';
              
    //           this.isSaveSuppleRequest = !this.isSaveSuppleRequest
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // saveSuppleRequestCallback(result) {
    //   this.popupParam.sopImprovementId = result.data
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.attachSupple.isSubmit = uid()
    //   this.getDetail();
    // },
    /**
     * 보완 정보 저장
     */
    // saveSupple() {
    //   this.$refs['editSuppleForm'].validate().then(_result => {
    //     if (_result) {
    //       window.getApp.$emit('CONFIRM', {
    //         title: '확인',
    //         message: '저장하시겠습니까?',
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.impr.chgUserId = this.$store.getters.user.userId;
              
    //           this.isSaveSupple = !this.isSaveSupple
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // saveSuppleCallback(result) {
    //   this.popupParam.sopImprovementId = result.data
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.attachSupple.isSubmit = uid()
    //   this.getDetail();
    // },
    /**
     * 보완 정보 저장
     */
    // saveSuppleActionReview() {
    //   this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
    //     if (_result) {
    //       window.getApp.$emit('CONFIRM', {
    //         title: '확인',
    //         message: '저장하시겠습니까?',
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.impr.chgUserId = this.$store.getters.user.userId;
              
    //           this.isSaveSuppleActionReview = !this.isSaveSuppleActionReview
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // saveSuppleActionReviewCallback(result) {
    //   this.popupParam.sopImprovementId = result.data
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.attachSupple.isSubmit = uid()
    //   this.getDetail();
    // },
    /**
     * 보완완료
     */
    // saveSuppleComplete() {
    //   this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
    //     if (_result) {
    //       window.getApp.$emit('CONFIRM', {
    //         title: '확인',
    //         message: '보완 완료하시겠습니까?',
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.impr.chgUserId = this.$store.getters.user.userId;
    //           this.impr.ibmSuppleStepCd = 'ISS0000015';
              
    //           this.isSaveSuppleComplete = !this.isSaveSuppleComplete
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // saveSuppleCompleteCallback(result) {
    //   this.popupParam.sopImprovementId = result.data
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.attachSupple.isSubmit = uid()
    //   this.getDetail();
    // },
    /**
     * 보완 검토요청 저장
     */
    // saveSuppleReview() {
    //   this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
    //     if (_result) {
    //       window.getApp.$emit('CONFIRM', {
    //         title: '확인',
    //         message: '검토 요청하시겠습니까?',
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.impr.chgUserId = this.$store.getters.user.userId;
    //           this.impr.ibmSuppleStepCd = 'ISS0000010';
              
    //           this.isSaveSuppleReview = !this.isSaveSuppleReview
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // saveSuppleReviewCallback(result) {
    //   this.popupParam.sopImprovementId = result.data
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.attachSupple.isSubmit = uid()
    //   this.getDetail();
    // },
    /**
     * 보완 반려
     */
    // returnSupple() {
    //   if (!this.impr.suppleReviewContents) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내', // 안내
    //       message: '보완검토내용에 반려사유를 입력하시기 바랍니다.', // 보완검토내용에 반려사유를 입력하시기 바랍니다.
    //       type: 'warning', // success / info / warning / error
    //     });
    //     return;
    //   }
    //   this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
    //     if (_result) {
    //       window.getApp.$emit('CONFIRM', {
    //         title: '확인',
    //         message: '반려하시겠습니까?',
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.impr.chgUserId = this.$store.getters.user.userId;
    //           this.impr.ibmSuppleStepCd = 'ISS0000005';
              
    //           this.isReturnSupple = !this.isReturnSupple
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // returnSuppleCallback(result) {
    //   this.popupParam.sopImprovementId = result.data
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.attachSupple.isSubmit = uid()
    //   this.getDetail();
    // },
    /**
     * 메뉴 트리용 기초 정보를 Tree 메뉴로 변환
     */
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upDeptCd',
        customID: 'deptCd',
      });
      return tree;
    },
  }
};
</script>